var $ = jQuery = require('../../../node_modules/jquery/dist/jquery.min.js');
var $ = jQuery = require('../../../node_modules/jquery/dist/jquery.slim.min.js');

$(document).ready(function() {
    
    if ($(window).width() > 1000) {
        $('.expand_prod, .product_expand').on('mouseover', function(){
            $('.product_expand').removeClass('block_hidden');
            $('.industries_expand').addClass('block_hidden');
            $('.expand_industr').removeClass('expand_border');
            $('.expand_prod').addClass('expand_border');
        });
        $('.expand_industr, .industries_expand').on('mouseover', function(){
            $('.industries_expand').removeClass('block_hidden');
            $('.product_expand').addClass('block_hidden');
            $('.expand_prod').removeClass('expand_border');
            $('.expand_industr').addClass('expand_border');
        });
        $('.product_expand, header').on('mouseleave', function(){
            $('.product_expand').addClass('block_hidden');
            $('.expand_prod').removeClass('expand_border');
        });
        $('.industries_expand, header').on('mouseleave', function(){
            $('.industries_expand').addClass('block_hidden');
            $('.expand_industr').removeClass('expand_border');
        });
    }
    

    $('.owl-nav').removeClass('disabled');
    $('.owl-nav').addClass('block_flex_space');

    $('ul.tabs li').click(function() {
		const tab_id = $(this).attr('data-tab');

		if ($(window).width() > 1140) {
			$('ul.tabs li').removeClass('current');
			$('.tab-content').removeClass('current');

			$(this).addClass('current');
			$("#" + tab_id).addClass('current');
		} else {
			$('ul.tabs li').removeClass('current');
			$('.tab-content').removeClass('current');

			$(this).addClass('current');
			$("#" + tab_id).addClass('current');
		}
	});

    $('.lets_talk').on('click', function() {
        $('.modal').removeClass('block_hidden');
        $('.header_menu').removeClass('block_show_mobile');
        
        $('body').css('overflow','hidden');
    });
    
    $('.close img').on('click', function() {
        $('.modal').addClass('block_hidden');
        $('body').css('overflow','auto');
    });

    /* ===== Logic for creating fake Select Boxes ===== */
    $('.sel').each(function() {
        $(this).children('select').css('display', 'none');
        
        var $current = $(this);
        
        $(this).find('option').each(function(i) {
            if (i == 0) {
                $current.prepend($('<div>', {
                class: $current.attr('class').replace(/sel/g, 'sel__box')
                }));
                
                var placeholder = $(this).text();
                $current.prepend($('<span>', {
                class: $current.attr('class').replace(/sel/g, 'sel__placeholder'),
                text: placeholder,
                'data-placeholder': placeholder
                }));
                
                return;
            }
            
            $current.children('div').append($('<span>', {
                class: $current.attr('class').replace(/sel/g, 'sel__box__options'),
                text: $(this).text()
            }));
        });
    });
    
    // Toggling the `.active` state on the `.sel`.
    $('.sel').click(function() {
        $(this).toggleClass('active');
    });
    
    // Toggling the `.selected` state on the options.
    $('.sel__box__options').click(function() {
        var txt = $(this).text();
        var index = $(this).index();
        
        $(this).siblings('.sel__box__options').removeClass('selected');
        $(this).addClass('selected');
        
        var $currentSel = $(this).closest('.sel');
        $currentSel.children('.sel__placeholder').text(txt);
        $currentSel.children('select').prop('selectedIndex', index + 1);
    });

    $('.insy_homepage select').change(function() {
        var text = $(this).find('option:selected').text();
        $('.sel').addClass('active');
        if (text == 'Broker'){
            $('.sel__box__options:nth-child(1)').trigger('click');
        } else if (text == 'Intermediary'){
            $('.sel__box__options:nth-child(2)').trigger('click');
        } else if (text == 'Business owner'){
            $('.sel__box__options:nth-child(3)').trigger('click');
        } else {
            $('.sel__box__options:nth-child(4)').trigger('click');
        }

        var $aux = $('<select/>').append($('<option/>').text(text));
        $(this).after($aux);
        $(this).width($aux.width());
        $aux.remove();

    }).change();

    $('.burger_icon').on('click', function() {
        $('.header_menu').toggleClass('block_show_mobile');
    });

    if ($(window).width() < 1000) {
        $('.expand_prod').on('click', function(){
            $('.product_expand').toggleClass('block_hidden');
            $('.industries_expand').addClass('block_hidden');
        });
        $('.expand_industr').on('click', function(){
            $('.industries_expand').toggleClass('block_hidden');
            $('.product_expand').addClass('block_hidden');
        });
    }

    $('.mail').on('input', function() {
        $('.mail_val').val($('.mail').val());
    });

});